import { Radio } from '@mantine/core'

interface QuestionRowProps {
  editing: boolean
  text: string
  value: boolean
  required?: boolean
  handleChange?: any
}

const QuestionRow = ({
  editing,
  text,
  value = false,
  required = false,
  handleChange,
}: QuestionRowProps) => {
  const groupValue = value?.toString()

  return (
    <div className="flex flex-col gap-4 sm:flex-row">
      <div className="flex w-full flex-row gap-1 text-sm font-medium text-doubleNickel-gray-700 sm:w-[80%]">
        {text}
        {required && <div className="text-doubleNickel-error-500">*</div>}
      </div>
      {editing ? (
        <Radio.Group
          className="w-32 sm:ml-auto"
          value={groupValue}
          onChange={handleChange}
          required={required}
        >
          <div className="flex flex-row gap-6">
            <Radio
              value="true"
              label="Yes"
              size="xs"
              classNames={{
                label: 'text-sm',
                radio: 'checked:bg-doubleNickel-brand-500',
              }}
            />
            <Radio
              value="false"
              label="No"
              size="xs"
              classNames={{
                label: 'text-sm',
                radio: 'checked:bg-doubleNickel-brand-500',
              }}
            />
          </div>
        </Radio.Group>
      ) : (
        <div className="ml-auto w-32 text-right text-sm font-medium text-doubleNickel-gray-700">
          {value === null ? 'N/A' : value ? 'Yes' : 'No'}
        </div>
      )}
    </div>
  )
}

export default QuestionRow
