import { Box } from '@mantine/core'
import { MonthPickerInput, MonthPickerInputProps } from '@mantine/dates'

import IconCalendar from 'src/icons/IconCalendar'

interface MonthInputProps extends MonthPickerInputProps {
  editing: boolean
  value: string
  handleChange?: any
  _minDate?: string
  _maxDate?: string
}

const MonthInput = ({
  editing,
  value,
  handleChange,
  _minDate = '1930-01-01',
  _maxDate = '2100-01-01',
  ...props
}: MonthInputProps) => {
  return (
    <Box
      onClick={(e) => {
        if (!editing) {
          e.stopPropagation()
          e.preventDefault()
        }
      }}
    >
      <MonthPickerInput
        leftSection={
          editing && (
            <IconCalendar className="fill-none stroke-doubleNickel-gray-500" />
          )
        }
        onClick={(event) => {
          if (editing) {
            event.stopPropagation()
          }
        }}
        valueFormat="MM/YYYY"
        placeholder={value}
        minDate={_minDate && new Date(_minDate)}
        maxDate={_maxDate && new Date(_maxDate)}
        classNames={{
          label: 'text-sm font-medium text-doubleNickel-gray-700',
          input: 'text-md text-doubleNickel-gray-900',
        }}
        value={value ? new Date(value) : null}
        variant={editing ? 'default' : 'unstyled'}
        onClickCapture={(e) => {
          if (!editing) {
            e.stopPropagation()
            e.preventDefault()
          }
        }}
        onChange={(event) => {
          handleChange(event)
        }}
        {...props}
      />
    </Box>
  )
}

export default MonthInput
